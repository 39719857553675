<template>
  <Feedbacks/>
</template>


<script>
export default {
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Feedbacks", path:"", isActive: true}] 
    }
  },
  components: {
    Feedbacks: () => import("../../src/components/feedbacks/Feedbacks"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>